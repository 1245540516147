<template>
  <b-form-group
    :label-for="id"
    :label-cols-md="column"
  >
    <template v-if="label" v-slot:label>
      <span style="font-size: 1rem; font-weight: 600;">
        {{ label }} <span v-if="required" class="text-danger">*</span>
      </span>
    </template>
    <b-input-group
      class="input-group-merge"
      :class="state ? 'is-valid' : state === null ? '' : 'is-invalid'"
    >
      <b-form-input
        :id="id"
        v-model="formValue"
        :placeholder="placeholder"
        type="number"
        :state="state"
        :readonly="readonly"
        :disabled="disabled"
        :style="customStyle"
        @blur="$emit('blur', $event)"
      />
      <b-input-group-append v-if="!readonly && !disabled && !required && clearable && value.length > 0" is-text>
        <feather-icon
          icon="XIcon"
          class="cursor-pointer"
          @click="$emit('input', '')"
        />
      </b-input-group-append>
      <b-input-group-append v-if="!readonly && !disabled && addMore" is-text>
        <feather-icon
          icon="PlusIcon"
          class="cursor-pointer"
          @click="$emit('addMore', '')"
        />
      </b-input-group-append>
    </b-input-group>
    <b-form-valid-feedback :state="state">
      {{ validFeedback }}
    </b-form-valid-feedback>
    <b-form-invalid-feedback :state="state">
      {{ invalidFeedback }}
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BFormValidFeedback,
  BFormInvalidFeedback,
} from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BFormValidFeedback,
    BFormInvalidFeedback,
  },
  props: {
    // Got value from v-model
    value: {
      type: [Number, String],
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    column: {
      type: String,
      required: false,
      default: '0',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    state: {
      type: Boolean,
      required: false,
      default: null,
    },
    validFeedback: {
      type: String,
      required: false,
      default: '',
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: '',
    },
    customStyle: {
      type: [String, Object],
      required: false,
      default: '',
    },
    addMore: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
