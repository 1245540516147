<template>
  <b-pagination
    v-model="formValue"
    size="lg"
    first-number
    last-number
    prev-class="prev-item"
    next-class="next-item"
    :per-page="perPage"
    :total-rows="totalRows"
  >
    <template #prev-text>
      <feather-icon
        icon="ChevronLeftIcon"
        size="18"
      />
    </template>
    <template #next-text>
      <feather-icon
        icon="ChevronRightIcon"
        size="18"
      />
    </template>
  </b-pagination>
</template>

<script>
import {
  BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BPagination,
  },
  props: {
    // Got value from v-model
    value: {
      type: Number || String,
      required: true,
    },
    perPage: {
      type: Number,
      required: true,
    },
    totalRows: {
      type: Number,
      required: true,
    },
  },
  computed: {
    formValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>
