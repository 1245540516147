export default {
  extractResponse(response) {
    return `<div>${response.data.message}</div>`
  },

  extractError(error) {
    let message = `<div>${error.response.data.message}</div>`

    // Extract laravel validation errors
    if (error.response.data.errors) {
      const { errors } = error.response.data
      const array = Object.values(errors)

      message += '<hr />'
      message += '<div style="text-align: left"><ul>'

      message += array.map(item => `<li>${item}</li>`)

      message += '</ul></div>'

      // Remove all comma from array mapping
      message = message.replace(/,/g, '')
    }

    return message
  },
}
