// คู่มือการใช้งาน
// https://sweetalert2.github.io/#configuration

export default {
  confirm: {
    icon: 'warning',
    allowOutsideClick: false,
    confirmButtonText: 'Yes',
    showCancelButton: true,
    reverseButtons: false, // Confirm on right side
    buttonsStyling: false,
    customClass: {
      cancelButton: 'btn btn-outline-secondary sweet-alert-cancel',
      confirmButton: 'btn btn-primary sweet-alert-confirm',
    },
    showClass: {
      popup: 'animate__animated animate__fadeIn',
    },
  },

  success: {
    icon: 'success',
    allowOutsideClick: false,
    customClass: {
      confirmButton: 'btn btn-primary sweet-alert-ok',
    },
    showClass: {
      popup: 'animate__animated animate__fadeIn',
    },
  },

  error: {
    icon: 'error',
    allowOutsideClick: false,
    customClass: {
      confirmButton: 'btn btn-primary sweet-alert-ok',
    },
    showClass: {
      popup: 'animate__animated animate__fadeIn',
    },
  },

  warning: {
    icon: 'warning',
    allowOutsideClick: false,
    customClass: {
      confirmButton: 'btn btn-primary sweet-alert-ok',
    },
    showClass: {
      popup: 'animate__animated animate__fadeIn',
    },
  },

  info: {
    icon: 'info',
    allowOutsideClick: false,
    customClass: {
      confirmButton: 'btn btn-primary sweet-alert-ok',
    },
    showClass: {
      popup: 'animate__animated animate__fadeIn',
    },
  },

  loading: {
    iconHtml: `
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    `,
    allowOutsideClick: false,
    showConfirmButton: false,
    customClass: {
      icon: 'sweet-alert-loading',
      confirmButton: 'btn btn-primary sweet-alert-ok',
    },
    showClass: {
      popup: 'animate__animated animate__fadeIn',
    },
  },

  errorCycleOverlapping: {
    icon: 'error',
    allowOutsideClick: false,
    customClass: {
      confirmButton: 'btn btn-primary sweet-alert-ok',
    },
    showClass: {
      popup: 'animate__animated animate__fadeIn',
    },
    width: '50vw',
  },
}
