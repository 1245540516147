export default class CampasPointLimit {
  constructor(item = {}) {
    return {
      id: item.id || '',
      user_id: item.user_id || '',
      user_name: item.user_name || '',
      max_points: item.max_points || 0,
      remaining_points: item.remaining_points || 0,
      hero_facebook_point: item.hero_facebook_point || 0,
      hero_google_point: item.hero_google_point || 0,
      hero_line_point: item.hero_line_point || 0,
      hero_tiktok_point: item.hero_tiktok_point || 0,
      hero_instagram_point: item.hero_instagram_point || 0,
      hero_linkedin_point: item.hero_linkedin_point || 0,
      hero_grab_ads_point: item.hero_grab_ads_point || 0,
      hero_lazada_point: item.hero_lazada_point || 0,
      hero_call_tracking_point: item.hero_call_tracking_point || 0,
      hero_pkkt_point: item.hero_pkkt_point || 0,
      hero_youtube_point: item.hero_youtube_point || 0,
      hero_popin_point: item.hero_popin_point || 0,
      hero_taboola_point: item.hero_taboola_point || 0,
      hero_twitter_point: item.hero_twitter_point || 0,
      hero_shopee_point: item.hero_shopee_point || 0,
      hero_innity_point: item.hero_innity_point || 0,
      hero_teads_point: item.hero_teads_point || 0,
      hero_landing_page_point: item.hero_landing_page_point || 0,
      hero_viu_point: item.hero_viu_point || 0,
      hero_influencer_point: item.hero_influencer_point || 0,
      hero_joox_point: item.hero_joox_point || 0,
      hero_gobear_point: item.hero_gobear_point || 0,
      hero_criteo_point: item.hero_criteo_point || 0,
      hero_freakout_point: item.hero_freakout_point || 0,
    }
  }
}
