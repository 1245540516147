<template>
  <b-card-actions
    ref="cardA"
    title="List"
    action-collapse
    action-refresh
    no-body
    @refresh="doLoadData('cardA')"
  >
    <b-card class="mb-2" no-body>
      <!-- !! Table Header -->
      <div class="mx-1">
        <b-row>
          <b-col class="mt-1" order="3" cols="12" order-sm="1" sm="6" md="4" lg="4">
            <HeroInputText
              id="filter"
              v-model="tableConfig.filter"
              placeholder="Search"
              @input="doTableFilter(500)"
            />
          </b-col>
          <b-col class="mt-1" order="1" cols="6" sm="4" md="3" lg="2">
            <HeroTablePerPage
              v-model="tableConfig.perPage"
              :options="tableConfig.perPageOptions"
              @input="doTableFilter(100)"
            />
          </b-col>
          <b-col class="mt-1" order="2" cols="6" sm="2" offset-md="2" md="3" offset-lg="4" lg="2">
            <HeroButtonModal
              v-if="$can('Edit', 'Campus Point Limits')"
              v-b-modal="'campas-point-limit-create-modal'"
              type="button"
              variant="primary"
            >
              Add
            </HeroButtonModal>
          </b-col>
        </b-row>
      </div>

      <!-- !! Table -->
      <b-row class="my-1">
        <b-col cols="12">
          <b-table
            show-empty
            striped
            sticky-header="100%"
            :responsive="true"
            :items="items"
            :fields="tableConfig.fields"
            :per-page="0"
            :sort-by="tableConfig.sortBy"
            :sort-direction="tableConfig.sortDirection"
            :no-sort-reset="true"
            :no-local-sorting="true"
            @sort-changed="doTableSort"
          >
            <template #head(user_name)="data">
              <div :style="`width: ${data.field.thwidth}px;`">
                {{ data.label }}
              </div>
            </template>
            <template #head(max_points)="data">
              <div :style="`width: ${data.field.thwidth}px;`">
                {{ data.label }}
              </div>
            </template>
            <!-- !! Action Column -->
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none action-button"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle"
                  />
                </template>
                <b-dropdown-item
                  v-if="$can('Edit', 'Campus Point Limits')"
                  v-b-modal="'campas-point-limit-edit-modal'"
                  @click="doEditData(data.item.id)"
                >
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="$can('Edit', 'Campus Point Limits')"
                  @click="doDeleteData(data.item.id)"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <!-- !! Table Footer -->
      <div class="mx-1 mb-1">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <HeroTablePagination
              v-model="tableConfig.currentPage"
              :per-page="tableConfig.perPage"
              :total-rows="tableConfig.totalRows"
              @input="doTableFilter(100)"
            />
          </b-col>
          <b-col cols="12" sm="6" class="d-flex align-items-top justify-content-center justify-content-sm-end">
            <HeroTableStatus :per-page="tableConfig.perPage" :total-rows="tableConfig.totalRows" />
          </b-col>
        </b-row>
      </div>
      <CampasPointLimitCreateModal @success="doLoadData" />
      <CampasPointLimitEditModal :edit-id="editId" @success="doLoadData" />
    </b-card>
  </b-card-actions>
</template>

<script>
import moment from 'moment'
import ErrorService from '@/services/ErrorService'
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import HeroButtonModal from '@/views/components/form/buttons/HeroButtonModal.vue'
import HeroInputText from '@/views/components/form/inputs/HeroInputText.vue'
import HeroTablePerPage from '@/views/components/table/HeroTablePerPage.vue'
import HeroTablePagination from '@/views/components/table/HeroTablePagination.vue'
import HeroTableStatus from '@/views/components/table/HeroTableStatus.vue'
import SweetAlert from '@/services/SweetAlert'
import CampasPointLimitCreateModal from '@/views/heroai/masters/campas-point-limit/components/CampasPointLimitCreateModal.vue'
import CampasPointLimitEditModal from '@/views/heroai/masters/campas-point-limit/components/CampasPointLimitEditModal.vue'
import axiosInstance from '@/libs/axiosInstance'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BDropdown,
    BDropdownItem,
    BCardActions,
    HeroButtonModal,
    HeroInputText,
    HeroTablePerPage,
    HeroTablePagination,
    HeroTableStatus,
    CampasPointLimitCreateModal,
    CampasPointLimitEditModal,
  },
  data() {
    return {
      editId: '',

      items: [],
      tableConfig: {
        filter: '',
        currentPage: 1,
        perPage: 25,
        perPageOptions: [5, 10, 25, 50, 75, 100],
        totalRows: 0,
        sortBy: 'created_at',
        sortDirection: 'desc',
        timeInterval: moment(),
        fields: [
          {
            label: 'Optimizer Name',
            key: 'user_name',
            sortable: true,
            stickyColumn: true,
            thwidth: '120',
          },
          {
            label: 'Max Points',
            key: 'max_points',
            sortable: true,
            stickyColumn: false,
            thwidth: '80',
          },

          {
            label: 'Hero Facebook',
            key: 'hero_facebook_point',
            sortable: true,
          },
          {
            label: 'Hero Google',
            key: 'hero_google_point',
            sortable: true,
          },
          {
            label: 'Hero Line',
            key: 'hero_line_point',
            sortable: true,
          },
          {
            label: 'Hero TikTok',
            key: 'hero_tiktok_point',
            sortable: true,
          },
          {
            label: 'Hero Instagram',
            key: 'hero_instagram_point',
            sortable: true,
          },
          {
            label: 'Hero LinkedIn',
            key: 'hero_linkedin_point',
            sortable: true,
          },
          {
            label: 'Hero Grab Ads',
            key: 'hero_grab_ads_point',
            sortable: true,
          },
          {
            label: 'Hero Lazada',
            key: 'hero_lazada_point',
            sortable: true,
          },
          {
            label: 'Hero Call Tracking',
            key: 'hero_call_tracking_point',
            sortable: true,
          },
          {
            label: 'Hero Pkkt',
            key: 'hero_pkkt_point',
            sortable: true,
          },
          {
            label: 'Hero YouTube',
            key: 'hero_youtube_point',
            sortable: true,
          },
          {
            label: 'Hero Popin',
            key: 'hero_popin_point',
            sortable: true,
          },
          {
            label: 'Hero Taboola',
            key: 'hero_taboola_point',
            sortable: true,
          },
          {
            label: 'Hero Twitter',
            key: 'hero_twitter_point',
            sortable: true,
          },
          {
            label: 'Hero Shopee',
            key: 'hero_shopee_point',
            sortable: true,
          },
          {
            label: 'Hero Innity',
            key: 'hero_innity_point',
            sortable: true,
          },
          {
            label: 'Hero Teads',
            key: 'hero_teads_point',
            sortable: true,
          },
          {
            label: 'Hero LandingPage',
            key: 'hero_landing_page_point',
            sortable: true,
          },
          {
            label: 'Hero Viu',
            key: 'hero_viu_point',
            sortable: true,
          },
          {
            label: 'Hero Influencer',
            key: 'hero_influencer_point',
            sortable: true,
          },
          {
            label: 'Hero Joox',
            key: 'hero_joox_point',
            sortable: true,
          },
          {
            label: 'Hero GoBear',
            key: 'hero_gobear_point',
            sortable: true,
          },
          {
            label: 'Hero Criteo',
            key: 'hero_criteo_point',
            sortable: true,
          },
          {
            label: 'Hero FreakOut',
            key: 'hero_freakout_point',
            sortable: true,
          },

          {
            label: 'Remaining Points',
            key: 'remaining_points',
            sortable: true,
          },
          {
            ...this.$can('Edit', 'Campus Point Limits') && {
              label: 'Action',
              key: 'action',
              sortable: false,
            },
          },
        ],
      },
    }
  },
  async mounted() {
    await this.doLoadData()
  },
  methods: {
    doTableFilter(timeout) {
      setTimeout(() => {
        if (moment().diff(this.tableConfig.timeInterval, 'milliseconds') > timeout) {
          this.tableConfig.timeInterval = moment()
          this.doLoadData()
        }
      }, timeout)
    },

    doTableSort(event) {
      this.tableConfig.sortBy = event.sortBy
      this.tableConfig.sortDirection = event.sortDesc ? 'desc' : 'asc'
      this.doLoadData()
    },

    async doLoadData() {
      this.$refs.cardA.showLoading = true

      try {
        const {
          perPage, currentPage, filter, sortBy, sortDirection,
        } = this.tableConfig

        const url = `campas-point-limit?perPage=${perPage}&page=${currentPage}&q=${filter}&orderByColumn=${sortBy}&orderByDirection=${sortDirection}`
        const response = await axiosInstance.get(url)

        this.tableConfig.currentPage = Number(response.data.data.current_page)
        this.tableConfig.perPage = Number(response.data.data.per_page)
        this.tableConfig.totalRows = Number(response.data.data.total)
        this.items = response.data.data.data
      } catch (error) {
        this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        this.tableConfig.totalRows = 0
      } finally {
        if (this.$refs.cardA) {
          this.$refs.cardA.showLoading = false
        }
      }
    },

    doEditData(id) {
      this.editId = id
    },

    async doDeleteData(id) {
      const alertResult = await this.$swal({ ...SweetAlert.confirm, text: 'Do you want to delete this campas point limit information?' })

      if (alertResult.value) {
        this.$refs.cardA.showLoading = true

        try {
          const response = await axiosInstance.delete(`campas-point-limit/${id}`)

          this.$swal({ ...SweetAlert.success, text: response.data.message })

          await this.doLoadData()
        } catch (error) {
          this.$swal({ ...SweetAlert.error, html: ErrorService.extractError(error) })
        } finally {
          if (this.$refs.cardA) {
            this.$refs.cardA.showLoading = false
          }
        }
      }
    },
  },
}
</script>
