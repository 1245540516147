<template>
  <b-button
    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
    :type="type"
    :variant="variant"
    :disabled="disabled"
    block
    @click="$emit('click', $event)"
  >
    <slot>Button</slot>
  </b-button>
</template>

<script>
import {
  BButton,
  VBModal,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    variant: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
    },
  },
}
</script>
